button.addStageButton {
  /* Box-model */
  margin-top: 1rem;
  padding: 1.8125rem 1rem 1.5625rem;
  width: 27.75rem;
  height: auto;
}
button.addStageButton [class~=MuiButton-label] {
  /* Flexbox Container */
  display: block;
}

.buttonTitle {
  /* Box-model */
  display: flex;
  /* Flexbox Container */
  align-items: center;
}

.addStageIcon {
  /* Box-model */
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

p.addStageDescription {
  /* Box-model */
  margin-top: 0.5rem;
  margin-bottom: 0;
  /* Typography */
  text-align: left;
}