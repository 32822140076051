:export {
  primary900: #042825;
  primary800: #215153;
  primary700: #267373;
  primary600: #00a3a9;
  primary500: #31cdd3;
  primary400: #1ae6e5;
  primary300: #9be4e4;
  primary200: #cbf4f4;
  primary100: #f2fafb;
  accent900: #442b3e;
  accent800: #660045;
  accent700: #a80072;
  accent600: #df53a7;
  accent500: #f579c3;
  accent400: #fea0d9;
  accent300: #ffc3e7;
  accent200: #ffddf1;
  accent100: #feecf7;
  gray900: #232e39;
  gray800: #434c53;
  gray700: #596a7c;
  gray600: #6c8a9b;
  gray500: #a1abb5;
  gray400: #c2ccd6;
  gray300: #dce2ea;
  gray200: #e7e7ea;
  gray100: #f3f3f5;
  gray90: #f9f9f9;
  gray80: #fcfcfc;
  gray000: #ffffff;
  stateSuccess700: #008a44;
  stateSuccess600: #20d077;
  stateSuccess500: #8fe8bd;
  stateSuccess400: #d2f7e5;
  stateSuccess200: #e2f6ed;
  stateWarning700: #c66b00;
  stateWarning600: #f3881b;
  stateWarning500: #f7b86e;
  stateWarning400: #fbdcb6;
  stateWarning200: #ffe5d6;
  destructive700: #c60027;
  stateError600: #e24362;
  stateError500: #efa2b0;
  stateError400: #f9d9e0;
  stateError300: #ffc7cd;
  destructive200: #ffdce4;
  destructive100: #ffeff3;
  informative900: #001459;
  informative800: #00239f;
  informative700: #0d39d4;
  informative600: #2d52d6;
  informative500: #456bf0;
  informative400: #5e81fd;
  informative300: #829dff;
  informative200: #b2c3ff;
  informative100: #e0e7ff;
  informative90: #eff2ff;
  informative80: #fafbfe;
  success900: #04381e;
  success800: #0d6639;
  success700: #008a44;
  success600: #10b461;
  success500: #4cc889;
  success400: #8fe8bd;
  success300: #d2f7e5;
  success200: #e2f6ed;
  success100: #e8fff4;
}

.circularProgress {
  display: grid !important;
  place-items: center !important;
  width: 100% !important;
  margin: 5% 0;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.paperContainer {
  padding: 1% !important;
  background-color: #f9f9f9;
  width: 1283px;
  height: 580px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.switchContainer {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 2% 0 0% 0;
  border: 0px !important;
}

.card {
  background-color: #ffffff;
  border-radius: 4px;
  width: 560px;
  height: 148px;
  left: 72px;
}

.card:first-child {
  margin-bottom: 16px;
  margin-right: 16px;
}

.card:nth-child(2) {
  margin-bottom: 16px;
}

.card:nth-child(3) {
  margin-right: 16px;
}

.cardContent {
  width: 560px;
  height: 148px;
  padding: 0%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.typography {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-left: 40px;
  padding-top: 24px;
  padding-bottom: 5px;
}

.formGroup {
  padding-left: 16px !important;
}

.constructionSwitch {
  width: 300px !important;
}