.schedulesContainer {
  min-width: 500px;
}

.containerItem {
  margin-left: 40px !important;
  width: 500px;
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.wrapper .container {
  text-align: center;
  margin: 10px;
}
.wrapper .container .label {
  font-weight: 700;
  margin-bottom: 5px;
}

.Badge {
  text-align: center;
  margin-left: 5px;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  overflow: hidden;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.2s;
  box-shadow: 0 2px 5px 0 #242427;
  background: #a2a2b4;
  top: 4px;
}

.Picker {
  width: 170px;
}

.SelectContainer {
  padding-bottom: 15px;
  width: 170px;
}