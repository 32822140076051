:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.Container {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  font-size: 14px;
}

.Card {
  flex: 1;
  background: #f4f9ff;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);
  padding: 10px;
  margin: 10px;
  min-width: 347.234px;
  border-radius: 8px;
  flex-wrap: nowrap;
}
.Card p {
  margin: 0px 85px 10px 25px;
}
.Card h3 {
  margin: 0px 10px 10px 25px;
}