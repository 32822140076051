.modal {
  z-index: 99999 !important;
}

.title {
  display: flex;
}
.title .button {
  margin-right: 0;
  margin-left: auto;
  background-color: transparent;
  color: #000;
  font-size: 16px;
}