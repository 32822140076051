:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.GreenHelper {
  width: 25px;
  height: 25px;
  margin-right: 4px;
  background-color: #16db72;
  border-radius: 5px;
}

.YellowHelper {
  width: 25px;
  height: 25px;
  margin-right: 4px;
  background-color: #a80072;
  border-radius: 5px;
}

.BlueHelper {
  width: 25px;
  height: 25px;
  margin-right: 4px;
  background-color: #3a7475;
  border-radius: 5px;
}