:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.indicatorContainer {
  display: flex;
  justify-content: space-around;
  margin: 20px 0px;
}

.indicator {
  display: flex;
  align-items: center;
}

.structuringBox {
  width: 20px;
  height: 20px;
  background-color: #a80072;
  margin-left: 5px;
  border-radius: 3px;
}

.presalesBox {
  width: 20px;
  height: 20px;
  background-color: #343438;
  margin-left: 5px;
  border-radius: 3px;
}

.constructionBox {
  width: 20px;
  height: 20px;
  background-color: #3a7475;
  margin-left: 5px;
  border-radius: 3px;
}

.deedBox {
  width: 20px;
  height: 20px;
  background-color: #e04a65;
  margin-left: 5px;
  border-radius: 3px;
}

.root {
  width: 100%;
}

.container {
  max-height: 540px;
}

.stickyCell {
  position: -webkit-sticky !important;
  position: sticky !important;
  background: #f3f3f5 !important;
  left: 0 !important;
  z-index: 1 !important;
  padding: 0px;
}

.columnDates {
  padding: 2px;
}

.noCompanySelected {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  color: #ff0000;
  font-size: 2rem;
}