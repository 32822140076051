.root {
  /* Box-model */
  padding: 0 1.5rem;
}

.titleContainer {
  /* Box-model */
  display: flex;
  margin-bottom: 0.5rem;
  /* Flexbox container */
  align-items: center;
}
.titleContainer > :first-child {
  /* Box-model */
  margin: 0 0.5rem 0 0;
}
.titleContainer > :last-child {
  /* Typography */
  font-style: italic;
  font-weight: 400;
}
.titleContainer.isHighlighted > :first-child {
  /* Typography */
  font-weight: 700;
}

.datesContainer {
  /* Box-model */
  display: flex;
}
.datesContainer > :last-child {
  /* Box-model */
  margin-left: 0.5rem;
}