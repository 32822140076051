@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Source+Sans+Pro&display=swap");
:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.Container {
  display: flex;
  justify-content: center;
}

.tableContainer {
  height: 300px;
}

.SoftGreenHelper {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background-color: #00dd6d;
  border-radius: 50%;
  font-family: "quicksand";
}

.GreenHelper {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background-color: #377476;
  border-radius: 50%;
  font-family: "quicksand";
}

.GrayHelper {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background-color: #d8d8d8;
  border-radius: 50%;
  font-family: "quicksand";
}

.ownerBadge {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background-color: #aa0073;
  border-radius: 50%;
}

.Label {
  font-size: 14px;
  margin-right: 14px;
}