.InputGroup {
  display: flex;
  height: 70px;
}
.InputGroup * {
  margin-left: 15px;
}

.ContainerFlex {
  display: grid;
  grid-template-columns: 12% 12% 12% 12% 12% 12% 12% 12% !important;
  align-items: center !important;
  overflow: auto;
}

.gridItem {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  margin: 0% 12% !important;
}