:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.HeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
}

.Search {
  border: 2px solid #f4f4ff;
  width: 40%;
  border-radius: 5px;
  padding: 0.8rem 0.5rem;
  justify-self: center;
}
.Search span {
  color: #bdbdc5;
}
.Search:hover {
  box-shadow: 2px 2px 3px rgba(82, 63, 105, 0.1);
  cursor: pointer;
}

.ButtonGo {
  justify-self: end;
  align-self: flex-start;
}

.buyerContainer {
  text-align: center;
}
.buyerContainer .bought {
  color: #018e42;
}

.tableContainer {
  max-height: 700px;
  overflow: auto;
}