.title {
  /* Box-model */
  display: flex;
}

div.value {
  /* Box-model */
  margin-top: 0.25rem;
}
div.value:empty::before {
  /* Box-model */
  content: "--";
}