.container {
  color: #4a5568;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  padding: 1em;
  overflow-y: auto;
}
.container .header {
  margin-bottom: 1em;
  display: flex;
}
.container .header .title {
  font-weight: 700;
  font-size: 1.875rem;
}
.container .header .actions {
  margin-right: 0;
  margin-left: auto;
}
.container .ratings .onBoarding {
  position: relative;
}