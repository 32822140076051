.DialogContainer {
  width: 100%;
}

.DialogTitle {
  display: flex;
  justify-content: center;
}

.strong {
  font-size: 2rem;
}

.formControl {
  display: flex;
  min-width: 400px;
}

.selectEmpty {
  margin-top: 30px;
}