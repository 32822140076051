.drawer {
  width: 80%;
  flex-shrink: 0;
}

.drawerPaper {
  width: 80%;
  overflow: auto;
}
.drawerPaper:focus, .drawerPaper:hover, .drawerPaper:active {
  outline: none;
}

.title {
  flex-grow: 1;
}