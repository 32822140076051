:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.priceContainer {
  display: flex;
}
.priceContainer .container {
  border-radius: 4px;
  margin: 15px 10px;
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
}
.priceContainer .container .title {
  text-align: center;
  font-weight: 700;
  font-size: 26px;
  display: block;
  margin-bottom: 25px;
}
.priceContainer .container .content {
  flex-grow: 1;
}
.priceContainer .container .content .stat {
  display: flex;
}
.priceContainer .container .content .stat .label {
  flex-grow: 1;
  font-weight: 700;
}