:export {
  primary900: #042825;
  primary800: #215153;
  primary700: #267373;
  primary600: #00a3a9;
  primary500: #31cdd3;
  primary400: #1ae6e5;
  primary300: #9be4e4;
  primary200: #cbf4f4;
  primary100: #f2fafb;
  accent900: #442b3e;
  accent800: #660045;
  accent700: #a80072;
  accent600: #df53a7;
  accent500: #f579c3;
  accent400: #fea0d9;
  accent300: #ffc3e7;
  accent200: #ffddf1;
  accent100: #feecf7;
  gray900: #232e39;
  gray800: #434c53;
  gray700: #596a7c;
  gray600: #6c8a9b;
  gray500: #a1abb5;
  gray400: #c2ccd6;
  gray300: #dce2ea;
  gray200: #e7e7ea;
  gray100: #f3f3f5;
  gray90: #f9f9f9;
  gray80: #fcfcfc;
  gray000: #ffffff;
  stateSuccess700: #008a44;
  stateSuccess600: #20d077;
  stateSuccess500: #8fe8bd;
  stateSuccess400: #d2f7e5;
  stateSuccess200: #e2f6ed;
  stateWarning700: #c66b00;
  stateWarning600: #f3881b;
  stateWarning500: #f7b86e;
  stateWarning400: #fbdcb6;
  stateWarning200: #ffe5d6;
  destructive700: #c60027;
  stateError600: #e24362;
  stateError500: #efa2b0;
  stateError400: #f9d9e0;
  stateError300: #ffc7cd;
  destructive200: #ffdce4;
  destructive100: #ffeff3;
  informative900: #001459;
  informative800: #00239f;
  informative700: #0d39d4;
  informative600: #2d52d6;
  informative500: #456bf0;
  informative400: #5e81fd;
  informative300: #829dff;
  informative200: #b2c3ff;
  informative100: #e0e7ff;
  informative90: #eff2ff;
  informative80: #fafbfe;
  success900: #04381e;
  success800: #0d6639;
  success700: #008a44;
  success600: #10b461;
  success500: #4cc889;
  success400: #8fe8bd;
  success300: #d2f7e5;
  success200: #e2f6ed;
  success100: #e8fff4;
}

.verticalLine {
  position: absolute;
  background-color: #00a3a9;
  width: 2px;
  height: calc(100%);
  left: 28px;
  top: 0;
}