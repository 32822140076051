:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.Container {
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  margin: 10px;
}

.Card {
  display: flex;
  max-width: 260px;
  min-width: 225px;
  width: 25%;
  background: #eef0f2;
  flex-direction: row;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.Icon {
  display: grid;
  background: #4ba3c3;
  align-content: center;
  font-size: 100%;
  padding: 0 24px;
  flex-direction: row;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.Label {
  color: #4ba3c3;
  margin: auto;
  padding: 12% 12% 0 12%;
  display: inline-block;
  white-space: nowrap;
}

.P {
  padding-left: 12%;
  padding-right: 12%;
}