.schedulesContainer {
  display: flex;
  overflow-y: scroll;
  overflow-x: scroll;
  max-height: 630px;
}

.title {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  font-weight: bold;
  text-shadow: -1px 2px #807e7e;
}

.towerName {
  margin-bottom: 20px;
  font-weight: bold;
  color: #7a7b7b;
}