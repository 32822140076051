.container {
  border-width: 0;
  display: flex;
  align-items: center;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: #a0aec0;
  color: #4a5568;
}
.container .input {
  font-family: inherit;
  font-size: inherit;
  appearance: none;
  background-color: transparent;
  border-style: none;
  width: 100%;
  color: #4a5568;
  line-height: 1.25;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.container .input:focus {
  outline: none;
}
.container:focus-within {
  border-color: #2b6cb0;
}