/* SETTINGS - @use must come first */
@use "./assets/styles/variables.scss" as vars;
@use "./assets/styles/settings/fonts.module.scss" as fonts;
@use "./assets/styles/generics/fontRoboto.module.scss" as roboto;

/* LIBS - Regular CSS imports come after @use */
@import "~@blueprintjs/core/lib/css/blueprint.css";

/* ELEMENTS */
html {
  /* Typography */
  font-size: 16px;
}

body {
  /* Box-model */
  margin: 0;
  padding: 0;
  height: 100vh;

  /* Typography */
  color: vars.$black-color;
  font-family: fonts.$body-font-family;
  font-size: 1rem;
  font-weight: 300;

  /* Visual */
  background: vars.$defaultWhite-color;
}

.MuiTypography-root {
  font-family: fonts.$body-font-family;
}

/* OBJECTS */

/* COMPONENTS */

/* UTILS */