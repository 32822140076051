:export {
  primary900: #042825;
  primary800: #215153;
  primary700: #267373;
  primary600: #00a3a9;
  primary500: #31cdd3;
  primary400: #1ae6e5;
  primary300: #9be4e4;
  primary200: #cbf4f4;
  primary100: #f2fafb;
  accent900: #442b3e;
  accent800: #660045;
  accent700: #a80072;
  accent600: #df53a7;
  accent500: #f579c3;
  accent400: #fea0d9;
  accent300: #ffc3e7;
  accent200: #ffddf1;
  accent100: #feecf7;
  gray900: #232e39;
  gray800: #434c53;
  gray700: #596a7c;
  gray600: #6c8a9b;
  gray500: #a1abb5;
  gray400: #c2ccd6;
  gray300: #dce2ea;
  gray200: #e7e7ea;
  gray100: #f3f3f5;
  gray90: #f9f9f9;
  gray80: #fcfcfc;
  gray000: #ffffff;
  stateSuccess700: #008a44;
  stateSuccess600: #20d077;
  stateSuccess500: #8fe8bd;
  stateSuccess400: #d2f7e5;
  stateSuccess200: #e2f6ed;
  stateWarning700: #c66b00;
  stateWarning600: #f3881b;
  stateWarning500: #f7b86e;
  stateWarning400: #fbdcb6;
  stateWarning200: #ffe5d6;
  destructive700: #c60027;
  stateError600: #e24362;
  stateError500: #efa2b0;
  stateError400: #f9d9e0;
  stateError300: #ffc7cd;
  destructive200: #ffdce4;
  destructive100: #ffeff3;
  informative900: #001459;
  informative800: #00239f;
  informative700: #0d39d4;
  informative600: #2d52d6;
  informative500: #456bf0;
  informative400: #5e81fd;
  informative300: #829dff;
  informative200: #b2c3ff;
  informative100: #e0e7ff;
  informative90: #eff2ff;
  informative80: #fafbfe;
  success900: #04381e;
  success800: #0d6639;
  success700: #008a44;
  success600: #10b461;
  success500: #4cc889;
  success400: #8fe8bd;
  success300: #d2f7e5;
  success200: #e2f6ed;
  success100: #e8fff4;
}

:export {
  primary900: #042825;
  primary800: #215153;
  primary700: #267373;
  primary600: #00a3a9;
  primary500: #31cdd3;
  primary400: #1ae6e5;
  primary300: #9be4e4;
  primary200: #cbf4f4;
  primary100: #f2fafb;
  accent900: #442b3e;
  accent800: #660045;
  accent700: #a80072;
  accent600: #df53a7;
  accent500: #f579c3;
  accent400: #fea0d9;
  accent300: #ffc3e7;
  accent200: #ffddf1;
  accent100: #feecf7;
  gray900: #232e39;
  gray800: #434c53;
  gray700: #596a7c;
  gray600: #6c8a9b;
  gray500: #a1abb5;
  gray400: #c2ccd6;
  gray300: #dce2ea;
  gray200: #e7e7ea;
  gray100: #f3f3f5;
  gray90: #f9f9f9;
  gray80: #fcfcfc;
  gray000: #ffffff;
  stateSuccess700: #008a44;
  stateSuccess600: #20d077;
  stateSuccess500: #8fe8bd;
  stateSuccess400: #d2f7e5;
  stateSuccess200: #e2f6ed;
  stateWarning700: #c66b00;
  stateWarning600: #f3881b;
  stateWarning500: #f7b86e;
  stateWarning400: #fbdcb6;
  stateWarning200: #ffe5d6;
  destructive700: #c60027;
  stateError600: #e24362;
  stateError500: #efa2b0;
  stateError400: #f9d9e0;
  stateError300: #ffc7cd;
  destructive200: #ffdce4;
  destructive100: #ffeff3;
  informative900: #001459;
  informative800: #00239f;
  informative700: #0d39d4;
  informative600: #2d52d6;
  informative500: #456bf0;
  informative400: #5e81fd;
  informative300: #829dff;
  informative200: #b2c3ff;
  informative100: #e0e7ff;
  informative90: #eff2ff;
  informative80: #fafbfe;
  success900: #04381e;
  success800: #0d6639;
  success700: #008a44;
  success600: #10b461;
  success500: #4cc889;
  success400: #8fe8bd;
  success300: #d2f7e5;
  success200: #e2f6ed;
  success100: #e8fff4;
}

:export {
  headline: "Roboto", sans-serif;
  body: "Roboto", sans-serif;
  headline1-color: #232e39;
  headline1-font-family: "Roboto", sans-serif;
  headline1-font-size: 2.875rem;
  headline1-font-style: normal;
  headline1-font-weight: 700;
  headline1-letter-spacing: 0;
  headline1-line-height: 3.375rem;
  headline2-color: #232e39;
  headline2-font-family: "Roboto", sans-serif;
  headline2-font-size: 2.125rem;
  headline2-font-style: normal;
  headline2-font-weight: 700;
  headline2-letter-spacing: 0.03125rem;
  headline2-line-height: 2.625rem;
  headline3-color: #232e39;
  headline3-font-family: "Roboto", sans-serif;
  headline3-font-size: 2rem;
  headline3-font-style: normal;
  headline3-font-weight: 700;
  headline3-letter-spacing: 0.03125rem;
  headline3-line-height: 2.5rem;
  headline4-color: #232e39;
  headline4-font-family: "Roboto", sans-serif;
  headline4-font-size: 1.75rem;
  headline4-font-style: normal;
  headline4-font-weight: 500;
  headline4-letter-spacing: 0;
  headline4-line-height: 2.125rem;
  headline5-color: #232e39;
  headline5-font-family: "Roboto", sans-serif;
  headline5-font-size: 1.5rem;
  headline5-font-style: normal;
  headline5-font-weight: 500;
  headline5-letter-spacing: 0;
  headline5-line-height: 1.875rem;
  headline6-color: #232e39;
  headline6-font-family: "Roboto", sans-serif;
  headline6-font-size: 1.25rem;
  headline6-font-style: normal;
  headline6-font-weight: 500;
  headline6-letter-spacing: 0.03125rem;
  headline6-line-height: 1.5rem;
  subtitle1-color: #434c53;
  subtitle1-font-family: "Roboto", sans-serif;
  subtitle1-font-size: 1rem;
  subtitle1-font-style: normal;
  subtitle1-font-weight: 500;
  subtitle1-letter-spacing: 0.03125rem;
  subtitle1-line-height: 1.25rem;
  subtitle2-color: #434c53;
  subtitle2-font-family: "Roboto", sans-serif;
  subtitle2-font-size: 1rem;
  subtitle2-font-style: normal;
  subtitle2-font-weight: 500;
  subtitle2-letter-spacing: 0.03125rem;
  subtitle2-line-height: 1.125rem;
  body1-color: #596a7c;
  body1-font-family: "Roboto", sans-serif;
  body1-font-size: 1rem;
  body1-font-style: normal;
  body1-font-weight: 400;
  body1-letter-spacing: 0;
  body1-line-height: 1.25rem;
  body2-color: #596a7c;
  body2-font-family: "Roboto", sans-serif;
  body2-font-size: 0.875rem;
  body2-font-style: normal;
  body2-font-weight: 400;
  body2-letter-spacing: 0;
  body2-line-height: 1.125rem;
  caption-color: #596a7c;
  caption-font-family: "Roboto", sans-serif;
  caption-font-size: 0.75rem;
  caption-font-style: normal;
  caption-font-weight: 300;
  caption-letter-spacing: 0.03125rem;
  caption-line-height: 1rem;
  button-medium-font-family: "Roboto", sans-serif;
  button-medium-font-size: 1.125rem;
  button-medium-font-weight: 500;
  button-medium-letter-spacing: 0.03125rem;
  button-medium-line-height: 1.375rem;
  button-small-font-family: "Roboto", sans-serif;
  button-small-font-size: 1rem;
  button-small-font-weight: 500;
  button-small-letter-spacing: 0.03125rem;
  button-small-line-height: 1.125rem;
}

.header {
  width: 100%;
  padding: 0 0 20px;
  display: inline-table;
}
.header h1 {
  /* Box-model */
  margin-top: 0;
  margin-bottom: 24px;
  /* Flexbox item */
  flex: none;
}
.header > .divider {
  margin: 10px 0;
}

.tools {
  /* Box-model */
  display: flex;
  /* Flexbox Container */
  align-items: center;
}

.filter {
  /* Box-model */
  display: flex;
  /* Flexbox container */
  align-items: center;
  /* Flexbox item */
  flex: none;
}

.buttons {
  /* Box-model */
  display: flex;
  /* Flexbox Container */
  justify-content: flex-end;
  /* Flexbox item */
  flex: 1 0 auto;
}
.buttons > * + * {
  /* Box-model */
  margin-left: 38px;
}

.button {
  /* Box-model */
  padding: 12px 24px;
  /* Typography */
  color: #00a3a9;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.03125rem;
  line-height: 1;
  text-transform: capitalize;
}
.button:hover {
  /* Visual */
  background-color: #31cdd317;
}
.button:focus {
  /* Visual */
  background-color: #9be4e4;
  outline: none;
}
.button:active {
  /* Typography */
  color: #215153;
  background-color: initial;
}

.buttonOutlined {
  /* Box-Model */
  padding: 10px 22px;
  /* Visual */
  border: 2px solid #00a3a9;
}

@media (max-width: 1439px) {
  .tools {
    /* Box-model */
    height: 72px;
    /* Visual */
    border-top: 1px solid #dce2ea;
  }
}
@media (min-width: 1440px) {
  .header {
    /* Box-model */
    height: 100px;
    /* FLexbox container */
    flex-direction: row;
    align-items: center;
  }
  .header h1 {
    /* Box-model */
    margin-bottom: 0;
  }

  .tools {
    /* Box-model */
    margin-left: 86px;
    /* Flexbox item */
    flex: 1 0 auto;
  }

  .buttons > * + * {
    /* Box-model */
    margin-left: 18px;
  }

  .button {
    /* Box-model */
    padding: 15px 32px;
    /* Typography */
    font-size: 1.125rem;
    letter-spacing: 0.03125rem;
  }

  .buttonOutlined {
    /* Box-Model */
    padding: 13px 30px;
  }
}