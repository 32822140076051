@charset "UTF-8";
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding: 10px;
  background: #ededed;
}
.Collapsible__trigger:after {
  font-weight: 900;
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}