h2.title {
  /* Box-model */
  margin-top: 0;
  margin-bottom: 1.5rem;
}

p.description {
  /* Box-model */
  margin: 0;
  width: 28.375rem;
}