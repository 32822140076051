.root {
  /* Box-model */
  display: flex;
  height: 100vh;
}

.sidebar {
  /* Flexbox Item */
  flex: none;
}

.content {
  /* Box-model */
  display: flex;
  height: 100%;
  width: 0;
  /* Flexbox Container */
  flex-direction: column;
  /* Flexbox Item */
  flex: auto;
}

.loader {
  /* Box-model */
  margin-top: 1.5rem;
  /* Flexbox Item */
  align-self: center;
}

p.emptyMessage {
  /* Box-model */
  margin-top: 5.5rem;
  /* Typography */
  text-align: center;
}

.noDataMessage {
  /* Box-model */
  display: flex;
  margin-top: 2.1875rem;
  /* Flexbox Container */
  flex-direction: column;
  align-items: center;
}

div.errorIcon {
  /* Box-model */
  margin-bottom: 1.5rem;
}