.itemRow {
  background-color: #F7F9FA;
}

.contractRow {
  background-color: #E1E7EC;
}

.utilityBeforeTaxRow {
  padding-top: 30px;
}