:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.SideMenu {
  display: flex !important;
  flex-direction: column;
  flex-basis: 5rem;
  flex-shrink: 0;
  color: #2d3436 !important;
  background: #fff;
  height: 100%;
  flex: 0.8;
  justify-content: flex-start;
  overflow: hidden;
  transition: all 0.2s ease;
  -webkit-box-shadow: 6px 3px 11px -4.5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 6px 3px 11px -4.5px rgba(0, 0, 0, 0.1);
  box-shadow: 6px 3px 11px -4.5px rgba(0, 0, 0, 0.1);
  border: red;
  z-index: 5;
  overflow-y: scroll;
}
.SideMenu:hover,
.SideMenu .collapseAndExpandRow {
  -webkit-box-shadow: 6px 3px 11px -4.5px #343438;
  -moz-box-shadow: 6px 3px 11px -4.5px #343438;
  box-shadow: 6px 3px 11px -4.5px #343438;
}

.ZeroWidth {
  width: 0px;
}

.OriginalWidth {
  width: 240px;
  overflow-y: auto;
}

.title {
  padding: 15px;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
  color: #f5f5f5;
  background-color: #343438 !important;
  -webkit-box-shadow: 0px 12px 33px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 12px 33px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 12px 33px -10px rgba(0, 0, 0, 0.57);
}
.title p {
  margin: 0px;
}

.towerName {
  opacity: 1 !important;
}

.collapseHeader {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
}

.iconLabel {
  font-size: 19px !important;
  margin-top: 1.5% !important;
  margin-right: 3.5% !important;
  padding-right: 5% !important;
}

.lineSpace {
  margin: 0.05% !important;
  opacity: 0.15 !important;
}

.IconsContainer {
  z-index: 3;
}

.Description {
  color: #2d3436;
  margin-left: 0.5%;
}

.DescriptionActive {
  color: #343438;
  background-color: #f5f5f5;
  margin-left: 0.5%;
}

.Start {
  margin-top: 3vh;
}

.End {
  margin-top: auto;
  margin-bottom: 3vh;
}

.MenuItem {
  display: flex;
  justify-content: center !important;
  width: 100vw !important;
  font-size: 13px !important;
  z-index: 3;
  color: #2d3436;
  background-color: #fff;
  width: 100vw !important;
}
.MenuItem a:link,
.MenuItem a:visited {
  padding: 15px 16px;
  flex: 1;
  border: none;
  transition-duration: 0.4s;
  text-decoration: none;
  cursor: pointer;
  color: #2d3436;
}
.MenuItem a:focus {
  color: #343438;
}
.MenuItem a:hover,
.MenuItem a:active {
  border-left: 5px solid #f4f4ff;
}

.Active {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f5f5f5 !important;
  font-size: 15px !important;
}
.Active a:link,
.Active a:visited {
  flex: 1;
  border: none;
  color: #343438;
  border-left: 5px solid #f4f4ff;
  padding: 10px 2px;
  transition-duration: 0.4s;
  text-decoration: none;
  font-size: 15px;
  cursor: pointer;
}
.Active a:hover,
.Active a:active {
  border-left: 5px solid #f4f4ff;
}

.fixedWidth {
  min-width: 150px;
  overflow-x: hidden;
}

.Visible {
  display: flex !important;
}

.NoVisible {
  display: none !important;
}

.Badge {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: #aa0606;
  overflow: hidden;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.2s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  background: #ee2e31;
  top: 5px;
  left: -5px;
}

.header {
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  margin: 15px 0;
  padding-left: 15px !important;
  opacity: 0.45;
}

.expansionPanel {
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 0 !important;
}

.expansionPanelActive {
  color: #343438 !important;
}

.expansionPanel:before {
  background-color: transparent !important;
}

.expansionPanelSummary {
  margin: 0 !important;
  padding: 0 10px !important;
  box-sizing: content-box;
  font-size: 16px;
}
.expansionPanelSummary:hover {
  background-color: whitesmoke !important;
}

.expansionPanelDetails {
  padding: 0 !important;
  margin-bottom: 15px;
  background-color: #ecf0f1 !important;
}

.linkContainer {
  display: flex;
  flex-direction: column;
}

.container {
  height: 100vh;
}