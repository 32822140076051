.areasContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.areasContainer .title {
  text-align: center;
  font-weight: 700;
  font-size: 26px;
  display: block;
  margin-bottom: 25px;
}
.areasContainer .content {
  flex-grow: 1;
}
.areasContainer .content .stat {
  display: flex;
}
.areasContainer .content .stat .label {
  flex-grow: 1;
  font-weight: 700;
}
.areasContainer .additionalAreas .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.areasContainer .additionalAreas .content .additionalArea {
  border-radius: 4px;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
  padding: 15px;
  width: 30%;
  box-sizing: border-box;
}
.areasContainer .additionalAreas .content .additionalArea .stat {
  display: flex;
}
.areasContainer .additionalAreas .content .additionalArea .stat .label {
  flex-grow: 1;
}