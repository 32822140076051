@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;600&family=Source+Sans+Pro&display=swap");
:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.container {
  margin-bottom: 14px !important;
  font-size: 12px;
  display: flex;
  flex-flow: wrap;
}
.container .outlineButton {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  min-width: 160px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: white;
  font-size: 16px;
  color: #00d0d4;
  font-family: "quicksand" !important;
  font-weight: 600 !important;
  margin-right: 10px;
}
.container .button {
  box-sizing: border-box;
  height: 40px;
  min-width: 160px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #e5f5f6;
  font-size: 16px;
  color: #00d0d4;
  font-family: "quicksand" !important;
  font-weight: 600;
  margin-right: 10px;
}
.container .titleText {
  display: grid;
  text-transform: uppercase;
  font-size: 12px !important;
  place-items: center;
}