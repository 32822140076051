.delete {
  color: #2D3748;
  font-size: 1.7em;
  padding: 5px;
}

.delete:hover {
  color: #29339B;
  font-size: 1.9em;
  padding: 5px;
}