:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.Sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 5rem;
  flex-shrink: 0;
  background: #343438;
  height: 100%;
  width: 80px;
}

.Start {
  margin-top: 3vh;
}

.End {
  margin-top: auto;
  margin-bottom: 3vh;
}

.logo {
  height: 40px;
  width: 70px;
}

.MenuItem {
  display: flex;
  justify-content: center;
}
.MenuItem a:link,
.MenuItem a:visited {
  border: none;
  color: #fff;
  padding: 10px 10px;
  transition-duration: 0.4s;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}
.MenuItem a:hover,
.MenuItem a:active {
  background-color: #a2a2b4;
  border-radius: 50%;
}