.button {
  border: 0;
  color: #00a3a9;
  text-transform: capitalize;
}

.hidden {
  display: none;
}

.body {
  padding: 0;
}