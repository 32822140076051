.container {
  display: flex;
  text-align: center;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.card {
  width: 320px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.card .title {
  margin-bottom: 20px;
}
.card .input {
  width: 100%;
  margin-bottom: 20px;
}
.card .submit {
  margin-bottom: 10px;
}
.card .link {
  text-decoration: none;
}