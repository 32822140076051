.info {
  display: flex;
  margin-bottom: 16px;
}
.info .client {
  font-weight: 700;
  font-size: 21px;
}
.info .property {
  margin-right: 0;
  margin-left: auto;
}
.info .property .name {
  font-weight: 700;
  font-size: 21px;
}

.details {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 14px;
}
.details .header {
  margin-bottom: 14px;
}

.pricing {
  margin: 20px 0;
}
.pricing .l0 {
  display: flex;
  margin-bottom: 5px;
}
.pricing .l0 .value {
  margin-right: 0;
  margin-left: auto;
}
.pricing .trade {
  display: flex;
  margin-bottom: 5px;
}
.pricing .trade .value {
  margin-right: 0;
  margin-left: auto;
}
.pricing .financial {
  display: flex;
  margin-bottom: 5px;
}
.pricing .financial .value {
  margin-right: 0;
  margin-left: auto;
}

.total {
  display: flex;
}
.total .title {
  font-weight: 700;
}
.total .value {
  font-weight: 700;
  font-size: 21px;
  margin-right: 0;
  margin-left: auto;
}