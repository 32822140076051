.container {
  width: 100% !important;
  padding: 2%;
}

.mainCont {
  display: grid;
  place-items: center;
}

.gridContainer {
  margin-top: 1% !important;
}

.paperContainer {
  margin-top: 1%;
  width: 65% !important;
  padding: 1.5%;
  justify-self: center !important;
}

.BuilderTable {
  width: 100vw !important;
}

.buttonForBackStep {
  display: flex !important;
  justify-content: flex-end !important;
}

.toolBar {
  display: flex !important;
  width: 100% !important;
}

.appBar {
  position: relative;
}

.gridContainerComplete {
  width: 100% !important;
}

:global(.MuiFormControl-fullWidth) {
  min-width: 120px !important;
}

.currencyField {
  width: 5vw !important;
}

.currencyFieldRender {
  width: 7vw !important;
}

.zerosAtTable {
  color: #cdcdcd !important;
}