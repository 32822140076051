.image-gallery {
  height: 55vh;
  width: calc(600px - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;
}
.image-gallery .image-gallery-content {
  display: flex;
  flex-direction: column;
}
.image-gallery .image-gallery-content .image-gallery-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40vh;
}
.image-gallery .image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 40vh;
}
.image-gallery .image-gallery-content .image-gallery-thumbnails .image-gallery-thumbnails-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}
.image-gallery .image-gallery-content .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail {
  height: auto;
}
.image-gallery .image-gallery-content.fullscreen .image-gallery-slide {
  height: 80vh;
}
.image-gallery .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
  max-height: 80vh;
}
.image-gallery .image-gallery-content.fullscreen .image-gallery-thumbnails-wrapper {
  height: auto;
}
.image-gallery .image-gallery-left-nav .image-gallery-svg,
.image-gallery .image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 30px;
}
.image-gallery .image-gallery-fullscreen-button .image-gallery-svg {
  height: 24px;
  width: 24px;
}
.image-gallery .image-gallery-thumbnail.active,
.image-gallery .image-gallery-thumbnail:hover,
.image-gallery .image-gallery-thumbnail:focus {
  border-color: #A80072;
}
.image-gallery .image-gallery-icon:hover {
  color: #A80072;
}
.image-gallery .image-gallery-icon:focus {
  outline: none;
}