.button {
  font-size: inherit;
  font-family: inherit;
  border: none;
  background-color: #e2e8f0;
  color: #2d3748;
  font-weight: 700;
  padding: 0.5em 1em;
  margin: 0 0.25em;
  border-radius: 0.25em;
}
.button:hover {
  background-color: #cbd5e0;
}
.button:disabled {
  background-color: #aab2bb;
}