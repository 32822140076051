.container .fabContainer {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px;
}
.container .actionContainer {
  margin-top: 15px;
}
.container .actionContainer .button {
  width: 100%;
}
.container .tableContainer {
  overflow: auto;
  max-height: 500px;
}

.tableRoot {
  width: auto !important;
}