.inputGroup {
  display: flex;
  margin-bottom: 25px;
}
.inputGroup .label {
  justify-content: center;
  display: flex;
  flex-flow: column;
  margin-right: 15px;
}
.inputGroup .label span {
  display: block;
}