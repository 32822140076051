.Container {
  display: flex;
}

.Container > * {
  flex-grow: 1;
}

.Container > * {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Input {
  width: 60%;
  font-size: 30px;
}

.Label {
  font-weight: bold;
  font-size: 13px;
  color: #a7abc3;
}

.Actions {
  display: flex;
  flex-direction: row-reverse;
}

.CancelButton {
  background: #ff4040 !important;
}

.RedText {
  color: red;
  font-weight: bold;
  white-space: nowrap;
}