.drawer *:focus {
  outline: none;
}

.container {
  position: relative;
  height: 100%;
  min-width: 510px;
  max-width: 510px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}