.noAdditionalAreasContainer {
  text-align: center;
  margin: 15px 0;
}

.subTotal {
  display: flex;
  margin: 10px 0;
}
.subTotal .label {
  font-weight: 700;
}
.subTotal .value {
  margin-right: 0;
  margin-left: auto;
}

.priceAndAction {
  display: grid !important;
  grid-template-columns: 80% 20%;
}

.price {
  margin-top: 3.5%;
  text-align: center;
}

.buttonClose {
  width: 16% !important;
}