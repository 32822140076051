.container {
  position: relative;
}
.container:hover .noHover {
  filter: blur(3px);
}
.container:hover .hover {
  opacity: 1;
}
.container .hover {
  display: flex;
  font-weight: 700;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
}
.container .noHover {
  transition: filter 150ms;
  opacity: 1;
}