.modalContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.modalContainer .addNewTemplateWrapper {
  position: relative;
  box-sizing: border-box;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 30px;
}
.modalContainer .addNewTemplateWrapper .header {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-basis: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.modalContainer .addNewTemplateWrapper .content {
  position: relative;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  max-height: 100%;
  overflow-y: hidden;
}
.modalContainer .addNewTemplateWrapper .content section {
  position: relative;
  box-sizing: border-box;
  padding: 30px;
  border-right: 1px dashed lightgray;
  flex-grow: 1;
  flex-basis: 50%;
  overflow-y: auto;
}
.modalContainer .addNewTemplateWrapper .content section.big {
  flex-basis: 70%;
}
.modalContainer .addNewTemplateWrapper .content section.small {
  flex-basis: 30%;
}
.modalContainer .addNewTemplateWrapper .content section:last-child {
  border-right: none;
}
.modalContainer .addNewTemplateWrapper .content section .variableList {
  font-size: 14px;
  line-height: 20px;
}
.modalContainer .addNewTemplateWrapper .content section .variableList .variableName {
  color: darkgray;
}
.modalContainer .addNewTemplateWrapper .content section .variableList .variableName::before {
  content: "{";
}
.modalContainer .addNewTemplateWrapper .content section .variableList .variableName::after {
  content: "}";
}
.modalContainer .addNewTemplateWrapper .content section .variableList .variableDescription::before {
  content: " - ";
}
.modalContainer .addNewTemplateWrapper .content section .variableList .variableDescription::after {
  content: ".";
}
.modalContainer .addNewTemplateWrapper .content section .form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.modalContainer .addNewTemplateWrapper .content section .form * {
  margin-bottom: 10px;
}
.modalContainer .addNewTemplateWrapper .footer {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-basis: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}
.modalContainer .addNewTemplateWrapper .footer .button {
  margin-left: 10px;
}