.wrapper {
  position: relative;
}
.wrapper .buttonProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-self: center;
}