.container {
  position: relative;
  background-color: #fff;
  line-height: 1;
  padding: 5px;
  margin: 5px;
  border-radius: 4px;
}
.container .actions {
  font-size: 8px;
  margin-top: 5px;
  opacity: 0.25;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
}
.container .actions .button {
  padding: 0;
  border-radius: 9999px;
  height: 15px;
  width: 15px;
}
.container .actions:hover {
  opacity: 1;
}
.container .input {
  margin-bottom: 5px;
}