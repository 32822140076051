:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

.Dashboard {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.Dashboard .Navigation {
  display: flex;
  flex-flow: row;
}
.Dashboard .Content {
  padding: 50px;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
.Dashboard .Content.removePadding {
  /* Box-model */
  padding: 0;
}
.Dashboard ::-webkit-scrollbar {
  width: 12px;
}
.Dashboard ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}
.Dashboard ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.blueBackground {
  background-color: #00A3A9;
}

.grayBackground {
  background-color: #e0e0e4;
}