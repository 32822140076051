.Container {
  margin-top: 32px;
  margin-left: 24px;
}

.FeesContainer {
  margin-left: 8px;
  margin-bottom: 16px;
}

.MonthlyFeeTitle {
  margin-right: 11px;
}