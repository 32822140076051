.root {
  /* Box-model */
  display: flex;
  padding: 2rem;
  /* Flexbox Container */
  justify-content: flex-end;
}
.root > * + * {
  /* Box-model */
  margin-left: 1rem;
}