/* SETTINGS - @use must come first */
/* LIBS - Regular CSS imports come after @use */
@import "~@blueprintjs/core/lib/css/blueprint.css";
:export {
  mainColor: #343438;
  mainBlueColor: #29339b;
  secondaryColor: #a80072;
  baseColor: #f3f3f5;
  mainGreenColor: #16db72;
  mainAquaGreen: #00a3ab;
  mainAquaGreenDarkColor: #3a7475;
  blueColor: #bcd2ee;
  blueLightColor: #00a3ab;
  softMainColor: #8a8fc8;
  redColor: #ee2e31;
  softRedColor: #f58d8e;
  greenColor: #018e42;
  softGreenColor: #74c197;
  yellowColor: #ffc857;
  softYellowColor: #ffe1a3;
  orangeColor: #f87060;
  softOrangeColor: #fcb8b1;
  purpleColor: #55495a;
  softPurpleColor: #94849b;
  grayColor: #a2a2b4;
  darkPurpleColor: #A057B3;
  darkGraycolor: #7D848B;
  itemNoActive: #ecf0f1;
  margin-content: 50px;
}

:export {
  primary900: #042825;
  primary800: #215153;
  primary700: #267373;
  primary600: #00a3a9;
  primary500: #31cdd3;
  primary400: #1ae6e5;
  primary300: #9be4e4;
  primary200: #cbf4f4;
  primary100: #f2fafb;
  accent900: #442b3e;
  accent800: #660045;
  accent700: #a80072;
  accent600: #df53a7;
  accent500: #f579c3;
  accent400: #fea0d9;
  accent300: #ffc3e7;
  accent200: #ffddf1;
  accent100: #feecf7;
  gray900: #232e39;
  gray800: #434c53;
  gray700: #596a7c;
  gray600: #6c8a9b;
  gray500: #a1abb5;
  gray400: #c2ccd6;
  gray300: #dce2ea;
  gray200: #e7e7ea;
  gray100: #f3f3f5;
  gray90: #f9f9f9;
  gray80: #fcfcfc;
  gray000: #ffffff;
  stateSuccess700: #008a44;
  stateSuccess600: #20d077;
  stateSuccess500: #8fe8bd;
  stateSuccess400: #d2f7e5;
  stateSuccess200: #e2f6ed;
  stateWarning700: #c66b00;
  stateWarning600: #f3881b;
  stateWarning500: #f7b86e;
  stateWarning400: #fbdcb6;
  stateWarning200: #ffe5d6;
  destructive700: #c60027;
  stateError600: #e24362;
  stateError500: #efa2b0;
  stateError400: #f9d9e0;
  stateError300: #ffc7cd;
  destructive200: #ffdce4;
  destructive100: #ffeff3;
  informative900: #001459;
  informative800: #00239f;
  informative700: #0d39d4;
  informative600: #2d52d6;
  informative500: #456bf0;
  informative400: #5e81fd;
  informative300: #829dff;
  informative200: #b2c3ff;
  informative100: #e0e7ff;
  informative90: #eff2ff;
  informative80: #fafbfe;
  success900: #04381e;
  success800: #0d6639;
  success700: #008a44;
  success600: #10b461;
  success500: #4cc889;
  success400: #8fe8bd;
  success300: #d2f7e5;
  success200: #e2f6ed;
  success100: #e8fff4;
}

:export {
  headline: "Roboto", sans-serif;
  body: "Roboto", sans-serif;
  headline1-color: #232e39;
  headline1-font-family: "Roboto", sans-serif;
  headline1-font-size: 2.875rem;
  headline1-font-style: normal;
  headline1-font-weight: 700;
  headline1-letter-spacing: 0;
  headline1-line-height: 3.375rem;
  headline2-color: #232e39;
  headline2-font-family: "Roboto", sans-serif;
  headline2-font-size: 2.125rem;
  headline2-font-style: normal;
  headline2-font-weight: 700;
  headline2-letter-spacing: 0.03125rem;
  headline2-line-height: 2.625rem;
  headline3-color: #232e39;
  headline3-font-family: "Roboto", sans-serif;
  headline3-font-size: 2rem;
  headline3-font-style: normal;
  headline3-font-weight: 700;
  headline3-letter-spacing: 0.03125rem;
  headline3-line-height: 2.5rem;
  headline4-color: #232e39;
  headline4-font-family: "Roboto", sans-serif;
  headline4-font-size: 1.75rem;
  headline4-font-style: normal;
  headline4-font-weight: 500;
  headline4-letter-spacing: 0;
  headline4-line-height: 2.125rem;
  headline5-color: #232e39;
  headline5-font-family: "Roboto", sans-serif;
  headline5-font-size: 1.5rem;
  headline5-font-style: normal;
  headline5-font-weight: 500;
  headline5-letter-spacing: 0;
  headline5-line-height: 1.875rem;
  headline6-color: #232e39;
  headline6-font-family: "Roboto", sans-serif;
  headline6-font-size: 1.25rem;
  headline6-font-style: normal;
  headline6-font-weight: 500;
  headline6-letter-spacing: 0.03125rem;
  headline6-line-height: 1.5rem;
  subtitle1-color: #434c53;
  subtitle1-font-family: "Roboto", sans-serif;
  subtitle1-font-size: 1rem;
  subtitle1-font-style: normal;
  subtitle1-font-weight: 500;
  subtitle1-letter-spacing: 0.03125rem;
  subtitle1-line-height: 1.25rem;
  subtitle2-color: #434c53;
  subtitle2-font-family: "Roboto", sans-serif;
  subtitle2-font-size: 1rem;
  subtitle2-font-style: normal;
  subtitle2-font-weight: 500;
  subtitle2-letter-spacing: 0.03125rem;
  subtitle2-line-height: 1.125rem;
  body1-color: #596a7c;
  body1-font-family: "Roboto", sans-serif;
  body1-font-size: 1rem;
  body1-font-style: normal;
  body1-font-weight: 400;
  body1-letter-spacing: 0;
  body1-line-height: 1.25rem;
  body2-color: #596a7c;
  body2-font-family: "Roboto", sans-serif;
  body2-font-size: 0.875rem;
  body2-font-style: normal;
  body2-font-weight: 400;
  body2-letter-spacing: 0;
  body2-line-height: 1.125rem;
  caption-color: #596a7c;
  caption-font-family: "Roboto", sans-serif;
  caption-font-size: 0.75rem;
  caption-font-style: normal;
  caption-font-weight: 300;
  caption-letter-spacing: 0.03125rem;
  caption-line-height: 1rem;
  button-medium-font-family: "Roboto", sans-serif;
  button-medium-font-size: 1.125rem;
  button-medium-font-weight: 500;
  button-medium-letter-spacing: 0.03125rem;
  button-medium-line-height: 1.375rem;
  button-small-font-family: "Roboto", sans-serif;
  button-small-font-size: 1rem;
  button-small-font-weight: 500;
  button-small-letter-spacing: 0.03125rem;
  button-small-line-height: 1.125rem;
}

/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fCxc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCxc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* ELEMENTS */
html {
  /* Typography */
  font-size: 16px;
}

body {
  /* Box-model */
  margin: 0;
  padding: 0;
  height: 100vh;
  /* Typography */
  color: #242427;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  /* Visual */
  background: #fafaff;
}

.MuiTypography-root {
  font-family: "Roboto", sans-serif;
}

/* OBJECTS */
/* COMPONENTS */
/* UTILS */